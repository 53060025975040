import { App } from 'vue'
import {

  /* 基础组件 */
  Button, //  按钮
  Cell, //  单元格
  CellGroup, //  单元格组
  // ConfigProvider, //  全局配置
  Icon, //  图标
  // Image, //  图片
  OverLay, //  遮罩层
  Popup, //  弹出层

  /* 布局组件 */
  Divider, //  分割线
  // Grid, //  宫格
  // GridItem, //  宫格项
  // Layout, //  布局
  Sticky, //  粘性布局
  Steps,
  Step,
  ImagePreview,
  Swiper,
  SwiperItem,
  Drag, // 拖动

  /* 导航组件 */
  // Elevator, //  电梯楼层
  // FixedNav, //  悬浮导航
  // Indicator, //  指示器
  MenuItem,
  Menu, //  菜单
  Navbar, //  头部导航
  // Pagination, //  分页
  // SideNavBar, //  侧边栏导航
  Tabbar, //  标签栏
  Tabs, //  选项卡切换
  TabPane, //  选项卡切换

  /* 数据录入 */
  // Calendar, //  日历
  // Cascader, //  级联选择器
  CheckboxGroup,
  Checkbox, //  复选按钮
  // DatePicker, //  日期选择器
  Form, //  表单
  FormItem, //  表单项
  Input, //  输入框
  InputNumber, //  数字输入框
  // NumberKeyboard, //  数字键盘
  // Picker, //  选择器
  RadioGroup, //  单选按钮
  Radio, //  单选按钮
  // Range, //  区间选择器
  // Rate, //  评分
  // SearchBar, //  搜索栏
  // ShortPassword, //  短密码
  TextArea, //  文本域
  Uploader, //  上传
  Switch,

  /* 操作反馈 */
  ActionSheet, //  动作面板
  // Audio, //  音频播放器
  // BackTop, //  返回顶部
  Dialog, //  对话框
  // Drag, //  拖拽
  // InfiniteLoading, //  滚动加载
  // Notify, //  消息通知
  // Swipe, //  滑动手势
  // Switch, //  开关
  // Toast, //  吐司

  /* 展示组件 */
  // Animate, //  动画/动效
  Avatar, //  头像
  // Badge, //  徽标
  // CircleProgress, //  环形进度条
  // Collapse, //  折叠面板
  CountDown, //  倒计时
  // CountUp, //  数字滚动
  // Ellipsis, //  文本省略
  Empty, //  空状态
  // ImagePreview, //  图片预览
  // List, //  虚拟列表
  // NoticeBar, //  公告栏
  // Popover, //  气泡弹出框
  // Price, //  价格
  Progress, //  进度条
  // Skeleton, //  骨架屏
  // Steps, //  步骤条
  // Swiper, //  轮播
  // Table, //  表格
  Tag, //  标签
  // TrendArrow, //  趋势箭头
  // Video, //  视频播放器
  // WaterMark, //  水印

  /* 特色组件 */
  // Address, //  地址
  // AddressList, //  地址列表
  // Barrage, //  弹幕
  // Card, //  商品卡片
  // Category, //  商品分类
  // Comment, //  商品评论
  // Ecard, //  虚拟电子卡
  Invoice, //  发票
  // Signature, //  签名
  // Sku, //  商品规格选择
  // TimeSelect, //  配送时间,


} from '@nutui/nutui-taro'

export function setupNutComponent(app: App<Element>) {
  const nutComponents = [

    /* 基础组件 */
    Button, //  按钮
    Cell, //  单元格
    CellGroup, //  单元格组
    // ConfigProvider, //  全局配置
    Icon, //  图标
    // Image, //  图片
    OverLay, //  遮罩层
    Popup, //  弹出层

    /* 布局组件 */
    Divider, //  分割线
    // Grid, //  宫格
    // GridItem, //  宫格项
    // Layout, //  布局
    Sticky, //  粘性布局
    Steps,
    Step,
    ImagePreview,
    OverLay,
    Swiper,
    SwiperItem,

    /* 导航组件 */
    // Elevator, //  电梯楼层
    // FixedNav, //  悬浮导航
    // Indicator, //  指示器
    MenuItem,
    Menu, //  菜单
    Navbar, //  头部导航
    // Pagination, //  分页
    // SideNavBar, //  侧边栏导航
    Tabbar, //  标签栏
    Tabs, //  选项卡切换
    TabPane, //  选项卡切换
    Drag, // 拖动

    /* 数据录入 */
    // Calendar, //  日历
    // Cascader, //  级联选择器
    CheckboxGroup,
    Checkbox, //  复选按钮
    // DatePicker, //  日期选择器
    Form, //  表单
    FormItem, //  表单项
    Input, //  输入框
    InputNumber, //  数字输入框
    // NumberKeyboard, //  数字键盘
    // Picker, //  选择器
    RadioGroup, //  单选按钮
    Radio, //  单选按钮
    // Range, //  区间选择器
    // Rate, //  评分
    // SearchBar, //  搜索栏
    // ShortPassword, //  短密码
    TextArea, //  文本域
    Uploader, //  上传

    /* 操作反馈 */
    ActionSheet, //  动作面板
    // Audio, //  音频播放器
    // BackTop, //  返回顶部
    Dialog, //  对话框
    // Drag, //  拖拽
    // InfiniteLoading, //  滚动加载
    // Notify, //  消息通知
    // Swipe, //  滑动手势
    // Switch, //  开关
    // Toast, //  吐司
    Switch, // 开关

    /* 展示组件 */
    // Animate, //  动画/动效
    Avatar, //  头像
    // Badge, //  徽标
    // CircleProgress, //  环形进度条
    // Collapse, //  折叠面板
    CountDown, //  倒计时
    // CountUp, //  数字滚动
    // Ellipsis, //  文本省略
    Empty, //  空状态
    // ImagePreview, //  图片预览
    // List, //  虚拟列表
    // NoticeBar, //  公告栏
    // Popover, //  气泡弹出框
    // Price, //  价格
    Progress, //  进度条
    // Skeleton, //  骨架屏
    // Steps, //  步骤条
    // Swiper, //  轮播
    // Table, //  表格
    Tag, //  标签
    // TrendArrow, //  趋势箭头
    // Video, //  视频播放器
    // WaterMark, //  水印

    /* 特色组件 */
    // Address, //  地址
    // AddressList, //  地址列表
    // Barrage, //  弹幕
    // Card, //  商品卡片
    // Category, //  商品分类
    // Comment, //  商品评论
    // Ecard, //  虚拟电子卡
    Invoice, //  发票
    // Signature, //  签名
    // Sku, //  商品规格选择
    // TimeSelect, //  配送时间,
  ]

  nutComponents.forEach(nutComponent => app.use(nutComponent))
}
